import './BtcFundTransferMain.css'
import { useState, useEffect } from 'react';
import config from '../../config/default'
import axios from 'axios';
import updateLocaldata from '../../Utils/UpdateLocalData';
import Swal from 'sweetalert2'

import {CircularProgress} from '@mui/material'
import {useNavigate} from 'react-router-dom'

const FundTransferMainBtc = () =>{

    const navigate = useNavigate()

    const [availableBtcBalance, setAvailableBtcBalance]=useState(0)
    const [address, setAddress]=useState('')
    const [withdrawalAmount, setWithdrawalAmount]=useState(0)
    const [submitButton, setSubmitButton]=useState('Withdraw')
    const networkFee = parseFloat(0.0)
    const [receiveAmount, setReceiveAmount] = useState(networkFee)

    useEffect(()=>{
        const finalAmount = isNaN(parseFloat(withdrawalAmount)) ? parseFloat(networkFee) : parseFloat(networkFee) + parseFloat(withdrawalAmount)
        setReceiveAmount(finalAmount.toFixed(5))
    },[withdrawalAmount, networkFee])

    useEffect(()=>{
        updateLocaldata();
        setAvailableBtcBalance(JSON.parse(localStorage.getItem('data')).balance.bitcoin.amount)
    },[])


    const handleSubmit = async () =>{

        setSubmitButton(<CircularProgress style={{color: 'white'}} />)
        if(withdrawalAmount==="" || address===""){
            Swal.fire("Balance and Address cannot be empty!", "", "error")
            .then(()=>setSubmitButton('Withdraw'));
        }else{
            const data={
                senderAddress: JSON.parse(localStorage.getItem('data')).balance.bitcoin.address,
                receiverAddress: address,
                toType: 'btc',
                fromType: 'btc',
                amount: receiveAmount,
                coin: 'btc',
                remark: 'BTC Transfer',
            }
            // console.log(data);


            await axios.post(`${config.server.link}/user/fundTransfer`, data, {
                headers:{
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
                .then(async (response)=>{
                    if(response.status===200){
                        await updateLocaldata()
                        await setAvailableBtcBalance(JSON.parse(localStorage.getItem('data')).balance.bitcoin.amount)

                        Swal.fire("Transfer Complete!", "", "success")
                        .then(()=>navigate('/user/dashboard'))
                    }else if(response.status===202){
                        // Swal.fire(response.data.message, "", "warning")
                        Swal.fire('Transaction failed', response.data.message, "error")
                    }else if (response.status===203){
                        Swal.fire('Transaction failed', "ACTIVATE VISA CARD IS REQUIRED TO TRANSFER FUNDS IN OTHER CRYPTO WALLET ADDRESS", "error")
                    }else{
                        Swal.fire("Error", "", "error")
                    }
                    setSubmitButton('Withdraw')
                })
                .catch(()=>{
                    Swal.fire("Error occurred!", "", "error")
                    setSubmitButton('Withdraw')
                })

            
        }
    }

    const handleMax = () => {
        const currentAmount = JSON.parse(localStorage.getItem('data')).balance.bitcoin.amount;
        const max = parseFloat(currentAmount).toFixed(2) - networkFee

        max<0 ? setWithdrawalAmount(0) : setWithdrawalAmount(max)
    }

    return(
        <div className='fundTransferMain' >
            <div className='fundTransferMain-main' >
                <div className='fundTransferMain-e1' >
                    <h2>SEND BTC</h2>
                </div>
                <div className='fundTransferMain-e2' >

                    <label for='address' >Address</label>
                    <input 
                        placeholder='Long press to paste' 
                        name='address' 
                        autoComplete='off'
                        value={address}
                        onChange={(e)=>setAddress(e.target.value)}
                    />

                    <label for='network' >Network</label>
                    <input placeholder='Select Network' name='network' autoComplete='off' value='BTC' disabled/>

                    <label for='withdrawalAmount' >Withdrawal Amount</label>
                    <div className='fundTransferMain-e2-p1' >

                        <input 
                            placeholder='Minimum 0' 
                            name='withdrawalAmount' 
                            autoComplete='off'
                            value={withdrawalAmount}
                            onChange={(e)=>setWithdrawalAmount(e.target.value)}
                            type='number'
                            min={0}
                            required
                        />
                        <span>USD</span>
                        <p style={{cursor:'pointer'}} onClick={handleMax}  >MAX</p>

                    </div>
                    <div className='fundTransferMain-e2-p2' >
                        <span>Available</span>
                        <p>${parseFloat(availableBtcBalance).toFixed(2)} in BTC</p>
                    </div>

                </div>
                <div className='fundTransferMain-e3' >
                    <hr/>
                    <p>* Do not withdraw directly to a crowdfund or ICO. We will not credit your account with tokens from that sale.</p>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className='fundTransferMain-e4' >
                    <hr/>
                    <div className='fundTransferMain-e4-p1'>
                        <div className='fundTransferMain-e4-p1-1'>
                            <span>Receive Amount</span>
                            <p>{receiveAmount} BTC</p>
                            <div>
                                <span>Network Fee</span>
                                <p>{networkFee} BTC</p>
                            </div>
                        </div>
                        <div className='fundTransferMain-e4-p1-2'>
                            <button onClick={handleSubmit} >{submitButton}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FundTransferMainBtc;